import { AddressResDto } from '../../../api/shared/res/address-res.dto';

export const AccountAddressComponent: React.FC<{ address: AddressResDto | null }> = function AccountAddressComponent({
  address,
}) {
  return (
    <>
      {!address ? (
        <>User has no address..</>
      ) : (
        <>
          <b>Shipping Address:</b>
          <p>
            {' '}
            {address.address}
            <br />
            {address.country}, {address.city}, {address.postalCode}
          </p>
        </>
      )}
    </>
  );
};
