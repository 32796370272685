import { TagsOutlined, UserOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { AccountTypeEnum } from '../../types/account-type.enum';

export const SideBarLinks = [
  {
    key: 'users',
    label: 'Users',
    requiredRoles: [AccountTypeEnum.Coach],
    icon: <UserSwitchOutlined />,
  },
  {
    key: 'https://c7653f-df.myshopify.com/', // will open in a new tab
    label: 'Online Store',
    requiredRoles: [AccountTypeEnum.Coach],
    icon: <TagsOutlined />,
  },
  {
    key: 'account',
    label: 'Account',
    requiredRoles: [AccountTypeEnum.Coach, AccountTypeEnum.Athlete],
    icon: <UserOutlined />,
  },
];
