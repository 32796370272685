import axios from 'axios';
import { API_URL } from '../../constants';
import { AuthStorageUtility } from '../../utilities/auth-storage.utility';

export class AuthServer {
  private readonly AUTH_API_PATH: string = `${API_URL}/auth`;

  async authenticate() {
    return await axios.get(this.AUTH_API_PATH);
  }

  logout(): void {
    AuthStorageUtility.deleteToken();
  }
}
