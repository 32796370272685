import { Checkbox, Spin } from 'antd';
import { Button, Col, Form, Grid, Row } from 'antd/es';
import { useForm } from 'antd/es/form/Form';
import FormItem from 'antd/es/form/FormItem';
import { countries } from 'countries-list';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IsErrorResDto } from '../../../api/error-res.dto';
import { ServerController } from '../../../api/server.controller';
import { PostPublicUpdatePersonalInfoReqDto } from '../../../api/shared/req/post-public-update-info-req.dto';
import FloatInput from '../../../components/float-components/float-input';
import FloatSelect from '../../../components/float-components/float-select';
import { AuthContext } from '../../../context/auth-context.provider';

const styles: any = {};

export const PersonalInfoForm: React.FC<{ closeModal: () => void }> = function PersonalInfoForm({ closeModal }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [form] = useForm<PostPublicUpdatePersonalInfoReqDto>();
  const [isBusinessPurchase, setBusinessPurchase] = useState(false);
  const translationPrefix = 'accountPage';
  const sizing = Grid.useBreakpoint();
  const [wantsToEnterBillingAddress, setWantsToEnterBillingAddress] = useState(false);
  const { user, getAndSetUser } = useContext(AuthContext);

  useEffect(() => {
    setLoading(true);
    getAndSetUser();
    setLoading(false);
  }, [getAndSetUser]);

  const countriesMemo = useMemo(() => {
    const countriesArray: {
      value: string;
      label: string;
    }[] = [];
    let countryKey: keyof typeof countries;
    for (countryKey in countries) {
      countriesArray.push({
        value: countryKey,
        label: countries[countryKey].name,
      });
    }
    return countriesArray;
  }, []);
  const submitChangePersonalInfo = async (values: PostPublicUpdatePersonalInfoReqDto) => {
    setLoading(true);
    const response = await ServerController.SharedUser.patch(values);
    if (!IsErrorResDto(response)) {
      closeModal();
      getAndSetUser();
    }
    setLoading(false);
  };

  const getUser = () => {
    if (user) {
      return user;
    }
    throw new Error();
  };

  return (
    <Spin spinning={loading}>
      <Form<PostPublicUpdatePersonalInfoReqDto>
        form={form}
        onFinish={submitChangePersonalInfo}
        initialValues={getUser()}
      >
        <Row className="row-form-item" gutter={12}>
          <Col xs={24} md={12}>
            <Form.Item
              name="firstName"
              rules={[
                {
                  required: true,
                  message: t(`${translationPrefix}.register.name.first.required`) as string,
                },
              ]}
            >
              <FloatInput label={t(`${translationPrefix}.register.name.first`)} required />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              name="lastName"
              rules={[{ required: true, message: t(`${translationPrefix}.register.name.last.required`) as string }]}
            >
              <FloatInput label={t(`${translationPrefix}.register.name.last`)} required />
            </Form.Item>
          </Col>
        </Row>
        <FormItem
          name={['shippingAddress', 'country']}
          rules={[
            {
              required: true,
              message: t(`${translationPrefix}.register.shippingAddress.country.required`) as string,
            },
          ]}
        >
          <FloatSelect
            options={countriesMemo}
            showSearch
            label={t(`${translationPrefix}.register.shippingAddress.country.label`)}
            filterOption={(input, option) => {
              const regex = new RegExp(input, 'i');
              return regex.test(option!.label as string);
            }}
            required
          />
        </FormItem>
        <Row className="row-form-item" gutter={12}>
          <Col xs={24} md={12}>
            <Form.Item
              name={['shippingAddress', 'postalCode']}
              rules={[
                {
                  required: true,
                  message: t(`${translationPrefix}.register.shippingAddress.postalCode.required`) as string,
                },
              ]}
            >
              <FloatInput label={t(`${translationPrefix}.register.shippingAddress.postalCode.label`)} required />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <FormItem
              name={['shippingAddress', 'city']}
              rules={[
                {
                  required: true,
                  message: t(`${translationPrefix}.register.shippingAddress.city.required`) as string,
                },
              ]}
            >
              <FloatInput label={t(`${translationPrefix}.register.shippingAddress.city.label`)} required />
            </FormItem>
          </Col>
        </Row>
        <FormItem
          name={['shippingAddress', 'address']}
          rules={[
            {
              required: true,
              message: t(`${translationPrefix}.register.shippingAddress.address.required`) as string,
            },
          ]}
        >
          <FloatInput label={t(`${translationPrefix}.register.shippingAddress.address.label`)} required />
        </FormItem>

        <FormItem name={['shippingAddress', 'state']}>
          <FloatInput label={t(`${translationPrefix}.register.shippingAddress.state.label`)} />
        </FormItem>

        <FormItem
          label={t(`${translationPrefix}.register.doesBillingEqualShipping`)}
          className={`${styles.labelText}`}
          name="wantsToEnterBillingAddress"
        >
          <Checkbox
            checked={wantsToEnterBillingAddress}
            onChange={(event) => setWantsToEnterBillingAddress(event.target.checked)}
          />
        </FormItem>
        {!wantsToEnterBillingAddress ? (
          <></>
        ) : (
          <>
            <FormItem
              name={['billingAddress', 'country']}
              rules={[
                {
                  required: true,
                  message: t(`${translationPrefix}.register.billingAddress.country.required`) as string,
                },
              ]}
            >
              <FloatSelect
                options={countriesMemo}
                showSearch
                label={t(`${translationPrefix}.register.billingAddress.country.label`)}
                filterOption={(input, option) => {
                  const regex: any = new RegExp(input, 'i');
                  return regex.test(option!.label as string);
                }}
                required
              />
            </FormItem>
            <Row className="row-form-item" gutter={12}>
              <Col xs={24} md={12}>
                <Form.Item
                  name={['billingAddress', 'postalCode']}
                  rules={[
                    {
                      required: true,
                      message: t(`${translationPrefix}.register.billingAddress.postalCode.required`) as string,
                    },
                  ]}
                >
                  <FloatInput label={t(`${translationPrefix}.register.billingAddress.postalCode.label`)} required />
                </Form.Item>
              </Col>
              <Col xs={24} md={12}>
                <FormItem
                  name={['billingAddress', 'city']}
                  rules={[
                    {
                      required: true,
                      message: t(`${translationPrefix}.register.billingAddress.city.required`) as string,
                    },
                  ]}
                >
                  <FloatInput label={t(`${translationPrefix}.register.billingAddress.city.label`)} required />
                </FormItem>
              </Col>
            </Row>
            <FormItem
              name={['billingAddress', 'address']}
              rules={[
                {
                  required: true,
                  message: t(`${translationPrefix}.register.billingAddress.address.required`) as string,
                },
              ]}
            >
              <FloatInput label={t(`${translationPrefix}.register.billingAddress.address.label`)} required />
            </FormItem>

            <FormItem name={['billingAddress', 'state']}>
              <FloatInput label={t(`${translationPrefix}.register.billingAddress.state.label`)} />
            </FormItem>
          </>
        )}

        <FormItem
          label={t(`${translationPrefix}.register.isBusinessPurchase`)}
          className={`${styles.labelText}`}
          name="isBusinessPurchase"
        >
          <Checkbox checked={isBusinessPurchase} onChange={(event) => setBusinessPurchase(event.target.checked)} />
        </FormItem>
        {!isBusinessPurchase ? (
          <></>
        ) : (
          <>
            <FormItem
              name={['taxInformation', 'businessName']}
              rules={[
                {
                  required: isBusinessPurchase,
                  message: t(`${translationPrefix}.register.taxInformation.businessName.required`) as string,
                },
              ]}
            >
              <FloatInput
                label={t(`${translationPrefix}.register.taxInformation.businessName.label`)}
                required={isBusinessPurchase}
              />
            </FormItem>
            <FormItem
              name={['taxInformation', 'taxId']}
              rules={[
                {
                  required: isBusinessPurchase,
                  message: t(`${translationPrefix}.register.taxInformation.taxId.required`) as string,
                },
              ]}
            >
              <FloatInput
                label={t(`${translationPrefix}.register.taxInformation.taxId.label`)}
                required={isBusinessPurchase}
              />
            </FormItem>
          </>
        )}

        <FormItem wrapperCol={sizing.xs === true ? undefined : { offset: 4, span: 16 }}>
          <Button type="primary" loading={loading} style={{ width: '100%' }} onClick={() => form.submit()}>
            {t(`save`)}
          </Button>
        </FormItem>
      </Form>
    </Spin>
  );
};
