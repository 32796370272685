import axios from 'axios';
import { API_URL } from '../../constants';
import { HandleAxiosErrorResDto, type ErrorResDto } from '../error-res.dto';
import type { PatchPasswordReqDto } from './req/patch-password-req.dto';
import type { PatchSharedUpdatePasswordResDto } from './res/patch-shared-update-password-res.dto';

export class SharedUpdatePasswordServer {
  private readonly API_PATH: string = `${API_URL}/shared/update/password`;

  async patch(data: PatchPasswordReqDto): Promise<PatchSharedUpdatePasswordResDto | ErrorResDto> {
    try {
      const response = await axios.patch<PatchSharedUpdatePasswordResDto>(`${this.API_PATH}`, data);
      return response.data;
    } catch (error) {
      return HandleAxiosErrorResDto(error);
    }
  }
}
