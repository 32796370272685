import { Avatar } from 'antd';
import { AvatarSize } from 'antd/lib/avatar/SizeContext';
import { UserResDto } from '../../api/shared/res/user-res.dto';
import { StringUtility } from '../../utilities/string.utility';

export const AvatarComponent: React.FC<{
  user: Pick<UserResDto, 'firstName' | 'lastName'>;
  size?: AvatarSize;
  showName?: boolean;
}> = function AvatarComponent({ user, size, showName }) {
  return (
    <>
      <Avatar style={{ backgroundColor: 'red', verticalAlign: 'middle' }} size={size}>
        {user.firstName?.[0]?.toUpperCase() || ''}
        {user.lastName?.[0]?.toUpperCase() || ''}
      </Avatar>
      {!showName ? (
        <></>
      ) : (
        <h1 className="user-name mb-2">
          {StringUtility.capitalizeFirstLetter(user.firstName)} {StringUtility.capitalizeFirstLetter(user.lastName)}
        </h1>
      )}
    </>
  );
};
