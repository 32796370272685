import { Col, Row, Spin } from 'antd';
import { FC } from 'react';

export const CenteredSpinnerComponent: FC<{ spinning: boolean }> = function CenteredSpinnerComponent({ spinning }) {
  return (
    <Row className="d-flex justify-content-center align-items-center" style={{ minHeight: '400px' }}>
      <Col>
        <Spin spinning={spinning}></Spin>
      </Col>
    </Row>
  );
};
