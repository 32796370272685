import { PageHeader, Row, Spin } from 'antd';
import { MouseEventHandler, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { IsErrorResDto } from '../../api/error-res.dto';
import { ServerController } from '../../api/server.controller';
import { Plan } from '../../types/plan';
import { ProductComponent, PublicOneTimeProductResDto } from './components/product.component';

const translationPrefix = 'planPage';
export const PlanPage = function PlanPage() {
  const [loading, setLoading] = useState<boolean>(false);
  const [plans, setPlans] = useState<Plan[] | null>(null);
  const [t] = useTranslation();

  const [product, setProduct] = useState<PublicOneTimeProductResDto | null>(null);

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      // const response = await Server.PublicPlan.get();
      // if (!IsErrorResDto(response)) {
      //   setPlans(response.data);
      // }
      const productResponse = await ServerController.PublicProduct.get();
      if (!IsErrorResDto(productResponse)) {
        setProduct(productResponse.data[0]);
      }
      setLoading(false);
    };
    load();
  }, []);

  const navigate = useNavigate();

  const onClick: MouseEventHandler = function (this: PublicOneTimeProductResDto, e) {
    navigate('purchase/kat', { state: this });
  };

  return (
    <>
      <Spin spinning={loading}>
        <div className="container">
          <PageHeader
            title={t(`${translationPrefix}.header.title`)}
            subTitle={t(`${translationPrefix}.header.subtitle`)}
          />
          <Row>{product && <ProductComponent product={product} onClick={onClick} />}</Row>
        </div>
      </Spin>
    </>
  );
};
