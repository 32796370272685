import { CheckOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row, Tag } from 'antd';
import dayjs from 'dayjs';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AthleteResDto } from '../../api/athlete/res/athlete-res.dto';
import { PaymentMethodResDto } from '../../api/coach/res/coach-payment-method-res.dto';
import { SubscriptionResDto } from '../../api/coach/res/subcription-res.dto';
import { IsErrorResDto } from '../../api/error-res.dto';
import { ServerController } from '../../api/server.controller';
import { UserResDto } from '../../api/shared/res/user-res.dto';
import { AuthContext } from '../../context/auth-context.provider';
import { AccountTypeEnum } from '../../types/account-type.enum';
import UserTotalCountComponent from '../user/components/user-total-count.component';
import { AccountAddressComponent } from './components/account-address.component';
import { ChangeEmail } from './modals/change-email';
import { ChangePassword } from './modals/change-password';
import UpdatePayment from './modals/update-payment';
import UpdatePersonalInfo from './modals/update-personal-info';

export enum CredentialsTypeOfChange {
  PASSWORD = 'Password',
  EMAIL = 'Email',
}
export enum BillingTypeOfChange {
  PAYMENT = 'payment',
  INFO = 'info',
}

export const UserInfoCard: React.FC<{
  user: UserResDto;
  subscription: SubscriptionResDto | null;
  paymentMethod: PaymentMethodResDto | null;
}> = function UserInfoCard({ user, subscription, paymentMethod }) {
  const { t } = useTranslation();
  const [paymentModal, setPaymentModal] = useState<boolean>(false);
  const [changePasswordModal, setChangePasswordModal] = useState<boolean>(false);
  const [changeEmailModal, setChangeEmailModal] = useState<boolean>(false);
  const [personalInfoModal, setPersonalInfoModal] = useState<boolean>(false);
  const { isInRole, logout } = useContext(AuthContext);
  const [myCoaches, setMyCoaches] = useState<AthleteResDto[] | null>(null);

  const loadMyCoaches = useCallback(async () => {
    if (isInRole(AccountTypeEnum.Athlete)) {
      const response = await ServerController.Athlete.get();
      if (!IsErrorResDto(response)) {
        setMyCoaches(response.data);
      }
      else {
        if(response.statusCode.toString().includes("403") ||response.statusCode.toString().includes("401") ){
          logout();
        }
      }
    }
  }, [isInRole]);
  useEffect(() => {
    loadMyCoaches();
  }, [loadMyCoaches]);

  return (
    <>
      <Card className="rounded shadow-sm mb-3">
        <Row>
          <Col span={24}>
            <h1 className="user-name mb-2">Personal information</h1>
          </Col>
          {isInRole(AccountTypeEnum.Athlete) && (
            <>
              <Col md={24}>
                <h3 className="fw-bold">My Admins</h3>
              </Col>
              {myCoaches?.map((x) => {
                return (
                  <>
                    <Col md={24} className="my-1">
                      <span className="me-1">{x.email}</span>
                      <span>
                        ({x.firstName} {x.lastName})
                      </span>
                    </Col>
                  </>
                );
              })}
            </>
          )}

          <Col span={24} sm={24} md={8} className="mt-2">
            <div>
              <b>Your email / username:</b>
              <br /> {user?.email}
            </div>
            <div>{isInRole(AccountTypeEnum.Coach) && <AccountAddressComponent address={user.shippingAddress} />}</div>
          </Col>
          <Col span={24} sm={24} md={8}>
            {user?.billingAddress && (
              <>
                <b>Billing Address:</b>
                <p>
                  {user?.billingAddress?.address}
                  <br />
                  {user?.billingAddress?.city}, {user?.billingAddress?.country}, {user?.billingAddress?.postalCode}
                </p>
              </>
            )}
          </Col>
          <Col span={24} sm={24} md={8}>
            {user?.taxInformation && (
              <>
                <p>
                  <b>Business name:</b>
                  <br /> {user.taxInformation.businessName}
                  <br />
                  <b>Tax ID:</b>
                  <br /> {user.taxInformation.taxId}
                </p>
              </>
            )}
          </Col>
          <Col span={24}>
            {isInRole(AccountTypeEnum.Coach) && (
              <Button type="dashed" className="me-2" onClick={() => setPersonalInfoModal(true)}>
                {t('accountPage.updatePersonalInfo')}
              </Button>
            )}
            <Button type="dashed" danger onClick={() => setChangePasswordModal(true)}>
              {t('accountPage.changePassword')}
            </Button>
          </Col>
        </Row>
      </Card>

      {isInRole(AccountTypeEnum.Coach) && (
        <>
          <Card className="rounded shadow-sm mb-3">
            <Row>
              <Col span={24}>
                <h1 className="user-name mb-2">License information</h1>
              </Col>
              <Col span={24} sm={24} md={8}>
                {subscription && subscription?.plan ? (
                  <>
                    <p>
                      <b>Added users:</b>
                      <br /> <UserTotalCountComponent />
                      <Button
                        type="link"
                        href="https://panthertec.helpshift.com/hc/en/3-panthertec-help-center/faq/70-want-more-users/"
                        target="_blank"
                      >
                        <u>Want more users?</u>
                      </Button>
                    </p>
                    <p>
                      <b> {t('accountPage.subscription')}:</b>
                      <br />
                      {dayjs(subscription.validUntil).isAfter(new Date()) ? (
                        <Tag color="green">{subscription.planDisplayName}</Tag>
                      ) : (
                        <Tag icon={<CheckOutlined />} color="red">
                          {t('accountPage.subscriptionInactive')}
                        </Tag>
                      )}
                    </p>
                    {/* <p>
                      <b>Trial Ends:</b>
                      <br /> {new Intl.DateTimeFormat('en', {}).format(new Date(subscription.trialEnds))}
                    </p> */}
                  </>
                ) : (
                  <>
                    {/* <p>
                    {t('accountPage.subscription')}:{' '}
                    <Tag icon={<CheckOutlined />} color="red">
                      {t('accountPage.subscriptionInactive')}
                    </Tag>
                  </p> */}
                  </>
                )}
              </Col>
              {/* <Col span={24} sm={24} md={8}>
                {paymentMethod && <PaymentMethodComponent paymentMethod={paymentMethod} />}
                <Button type="dashed" onClick={() => setPaymentModal(true)} className="mt-1">
                  {paymentMethod ? t('accountPage.updatePayment') : t('accountPage.addPaymentMethod')}
                </Button>
              </Col> */}
            </Row>
          </Card>
        </>
      )}

      <UpdatePayment open={paymentModal} paymentMethod={paymentMethod} close={() => setPaymentModal(false)} />
      <UpdatePersonalInfo open={personalInfoModal} close={() => setPersonalInfoModal(false)} />
      <ChangePassword open={changePasswordModal} closeModal={() => setChangePasswordModal(false)} />
      <ChangeEmail open={changeEmailModal} closeModal={() => setChangeEmailModal(false)} />
    </>
  );
};
