import { LikeFilled } from '@ant-design/icons';

export const successMessage = (content: string) => {
  return {
    content: content,
    icon: <LikeFilled />,
    className: 'success',
    style: {
      fontWeight: 600,
      color: 'white',
    },
    duration: 1.5,
  };
};
