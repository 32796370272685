import axios from 'axios';
import { API_URL } from '../../constants';
import { ErrorResDto, HandleAxiosErrorResDto } from '../error-res.dto';
import { GetAthleteResDto } from './res/get-athlete-res.dto';

export class AthleteServer {
  private readonly ATHLETE_API_PATH: string = `${API_URL}/athlete`;

  async get(): Promise<GetAthleteResDto | ErrorResDto> {
    try {
      const athletesRes = await axios.get<GetAthleteResDto>(`${this.ATHLETE_API_PATH}`);
      return athletesRes.data;
    } catch (err) {
      return HandleAxiosErrorResDto(err);
    }
  }
}
