import axios from 'axios';
import { API_URL } from '../../constants';
import { ErrorResDto, HandleAxiosErrorResDto } from '../error-res.dto';
import { PostResDto } from '../post-res.dto';
import { PatchAthleteReqDto } from '../shared/req/patch-athlete-req.dto';
import { PostAthleteReqDto } from '../shared/req/post-athlete-req.dto';
import { GetCoachAthleteResDto } from './res/get-coach-athlete-res.dto';

export class CoachAthleteServer {
  private readonly ATHLETE_API_PATH: string = `${API_URL}/coach/athlete`;

  async get(id?: string): Promise<GetCoachAthleteResDto | ErrorResDto> {
    try {
      const athletesRes = await axios.get<GetCoachAthleteResDto>(`${this.ATHLETE_API_PATH}/${id ?? ''}`);
      return athletesRes.data;
    } catch (err) {
      return HandleAxiosErrorResDto(err);
    }
  }

  async post(createAthleteDto: Partial<PostAthleteReqDto>): Promise<PostResDto | ErrorResDto> {
    try {
      const response = await axios<PostResDto>({
        method: 'POST',
        url: `${this.ATHLETE_API_PATH}`,
        responseType: 'json',
        withCredentials: true,
        data: createAthleteDto,
      });
      return response.data;
    } catch (err) {
      return HandleAxiosErrorResDto(err);
    }
  }

  async patch(dto: PatchAthleteReqDto, athleteId: string): Promise<PostResDto | ErrorResDto> {
    try {
      const response = await axios({
        method: 'PATCH',
        url: `${this.ATHLETE_API_PATH}/${athleteId}`,
        responseType: 'json',
        withCredentials: true,
        data: dto,
      });
      return response.data;
    } catch (err) {
      return HandleAxiosErrorResDto(err);
    }
  }

  async delete(id: string): Promise<PostResDto | ErrorResDto> {
    try {
      const response = await axios({
        method: 'DELETE',
        url: `${this.ATHLETE_API_PATH}/${id}`,
      });
      return response.data;
    } catch (err) {
      return HandleAxiosErrorResDto(err);
    }
  }
}
