import { Fragment, memo } from 'react';
import { Navigate, Route, Routes } from 'react-router';
import { UserResDto } from '../../api/shared/res/user-res.dto';
import { RoutesDefinitions } from './pages.router-definitions';

export const PagesRouter: React.FC<{ user: UserResDto }> = memo(({ user }) => {
  return (
    <Routes>
      {RoutesDefinitions.map((x) => {
        return (
          <Fragment key={x.path}>
            {user.roles.some((y) => x.requiredRoles?.includes(y as any)) ? (
              <Route key={x.path} path={x.path} element={x.element}>
                {x.subroutes.map((subroute) => {
                  const isIndexRoute = subroute.path === '';
                  if (isIndexRoute) {
                    return <Route key={subroute.path} index element={subroute.element} />;
                  }
                  return <Route key={subroute.path} path={subroute.path} element={subroute.element} />;
                })}
              </Route>
            ) : (
              <></>
            )}
          </Fragment>
        );
      })}
      <Route path="/*" element={<Navigate to="/account" />} />
    </Routes>
  );
});
