import axios from 'axios';
import { API_URL } from '../../constants';
import { ErrorResDto, HandleAxiosErrorResDto } from '../error-res.dto';
import { PostResDto } from '../post-res.dto';
import type { PostCoachAthleteEmailRegistrationReqDto } from './req/post-coach-athlete-email-registration-req.dto';

export class CoachAthleteEmailRegistrationServer {
  private readonly ATHLETE_API_PATH: string = `${API_URL}/coach/athlete/email/registration`;

  async post(data: PostCoachAthleteEmailRegistrationReqDto): Promise<PostResDto | ErrorResDto> {
    try {
      const response = await axios<PostResDto>({
        method: 'POST',
        url: `${this.ATHLETE_API_PATH}`,
        responseType: 'json',
        withCredentials: true,
        data,
      });
      return response.data;
    } catch (err) {
      return HandleAxiosErrorResDto(err);
    }
  }
}
