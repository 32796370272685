import { useState } from 'react';
import { Input } from 'antd';

import './style.css';

interface Props {
  label?: string;
  value?: any;
  placeholder?: string;
  type?: string;
  required?: boolean;
  addonBefore?: any;
  disabled?: boolean;
  readOnly?: boolean;
  onChange?: (e: any) => void;
  maxLength?: number;
  showCount?: boolean;
}

const FloatInput = (props: Props) => {
  const [focus, setFocus] = useState(false);
  let { label, value, placeholder, type, required, addonBefore, disabled, onChange, readOnly, maxLength, showCount } =
    props;

  if (!placeholder) placeholder = label;

  const isOccupied = focus || (value && value.length !== 0);

  const labelClass = isOccupied ? 'label as-label' : 'label as-placeholder';

  const requiredMark = required ? (
    <span className="text-danger" style={{ color: 'red' }}>
      *
    </span>
  ) : null;

  return (
    <div className="float-label" onBlur={() => setFocus(false)} onFocus={() => setFocus(true)}>
      <Input
        className="float-input"
        addonBefore={addonBefore}
        onChange={onChange}
        type={type}
        defaultValue={value}
        style={{ height: 40 }}
        readOnly={readOnly}
        disabled={disabled}
        maxLength={maxLength}
        showCount={showCount}
      />
      <label className={labelClass}>
        {isOccupied ? label : placeholder} {requiredMark}
      </label>
    </div>
  );
};

export default FloatInput;
