import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './en.json';
let isInitialized = false;
export function initI18n() {
  if (isInitialized) {
    return;
  }
  // the translations
  const resources = {
    en: {
      translation: en,
    },
  };

  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      fallbackLng: 'en',
      resources,
      lng: 'en',
    });
  isInitialized = true;
}

export const GetAndInitI18N = function () {
  initI18n();
  return i18n;
};
