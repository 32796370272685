import axios from 'axios';
import { API_URL } from '../../constants';
import { ErrorResDto, HandleAxiosErrorResDto } from '../error-res.dto';
import { PlanResDto } from '../shared/res/plan-res.dto';

export class PublicPlanServer {
  private readonly PLAN_API_PATH = `${API_URL}/public/plan`;

  async get(): Promise<Promise<PlanResDto | ErrorResDto>> {
    try {
      const productList = await axios.get(this.PLAN_API_PATH);
      return productList.data;
    } catch (err) {
      return HandleAxiosErrorResDto(err);
    }
  }
}
