import axios from 'axios';
import { API_URL } from '../../constants';
import { ErrorResDto, HandleAxiosErrorResDto } from '../error-res.dto';
import { SetupIntentResDto } from '../shared/res/setup-intent-res.dto';

export class Stripe {
  private readonly STRIPE_PATH = `${API_URL}/stripe`;

  async fetchSetupIntent(): Promise<SetupIntentResDto | ErrorResDto> {
    try {
      const response = await axios({
        method: 'GET',
        url: `${this.STRIPE_PATH}/setup/intent`,
        responseType: 'json',
        withCredentials: true,
      });
      return response.data;
    } catch (err) {
      return HandleAxiosErrorResDto(err);
    }
  }
}
