import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import type { StripePaymentElement } from '@stripe/stripe-js';
import { Button, Col, Result, Row, Spin, message } from 'antd';
import { MouseEventHandler, useState } from 'react';

export const PurchaseDeviceComponent = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setLoading] = useState(false);
  const onClick: MouseEventHandler = async (e) => {
    try {
      setLoading(true);
      e.preventDefault();
      e.stopPropagation();

      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        redirect: 'if_required',

        confirmParams: {
          // Make sure to change this to your payment completion page
          save_payment_method: true,
        },
      });
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      if (error) {
        if (error.type === 'card_error' || error.type === 'validation_error') {
          message.error(error.message);
        } else {
          message.error('An unexpected error occurred. Please try again');
          return;
        }
      }
      if (paymentIntent?.status === 'succeeded') {
        setPaymentSuccess(true);
      }
    } finally {
      setLoading(false);
    }
  };

  const onReady = (_: StripePaymentElement) => {
    setLoading(false);
  };

  const onLoadError = () => {
    message.error('Error getting payment provider');
  };

  const [paymentSuccess, setPaymentSuccess] = useState(false);
  return (
    <>
      {paymentSuccess ? (
        <>
          <Result status="success" title="Thank you for your purchase" subTitle="Check your email for an Receipt" />
        </>
      ) : (
        <>
          <PaymentElement id="payment-element" onReady={onReady} onLoadError={onLoadError} />
          <Row className="d-flex justify-content-center align-items-center mt-2">
            <Col>
              <Button type="primary" disabled={isLoading} onClick={onClick}>
                {isLoading ? <Spin spinning={isLoading} /> : 'Pay'}
              </Button>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
