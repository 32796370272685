import { DeleteOutlined, EditOutlined, UserAddOutlined } from '@ant-design/icons';

import { Avatar, message } from 'antd';
import { Button, Card, Col, Form, Grid, Row } from 'antd/es';
import { useForm } from 'antd/es/form/Form';
import FormItem from 'antd/es/form/FormItem';
import { countries } from 'countries-list';
import { ChangeEvent, useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { IsErrorResDto } from '../../api/error-res.dto';
import { ServerController } from '../../api/server.controller';
import { PostAthleteReqDto } from '../../api/shared/req/post-athlete-req.dto';
import { successMessage } from '../../components/antd-notifications/antd-notifications';
import FloatInput from '../../components/float-components/float-input';
import { AuthContext } from '../../context/auth-context.provider';

export const AddUserPage: React.FC = function RegisterPage() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [form] = useForm<PostAthleteReqDto>();
  const translationPrefix = 'accountPage';
  const sizing = Grid.useBreakpoint();
  const [hasAcceptedTermsAndConditions, setHasAcceptedTermsAndConditions] = useState(false);
  const navigate = useNavigate();
  const { getAndSetUser } = useContext(AuthContext);

  const countriesMemo = useMemo(() => {
    const countriesArray: {
      value: string;
      label: string;
    }[] = [];
    let countryKey: keyof typeof countries;
    for (countryKey in countries) {
      countriesArray.push({
        value: countryKey,
        label: countries[countryKey].name,
      });
    }
    return countriesArray;
  }, []);
  const handleRegister = async (values: any) => {
    setLoading(true);
    const userRegistrationObject: Partial<PostAthleteReqDto> = {
      email: values.email,
      password: values.password,
      confirmPassword: values.repeatedPassword,
      firstName: values.firstName,
      lastName: values.lastName,
      // phoneNumber: values.phoneNumber,
      // hasAcceptedTermsAndConditions: hasAcceptedTermsAndConditions,
      // shippingAddress: {
      //   state: values.shippingAddress.state,
      //   country: values.shippingAddress.country,
      //   address: values.shippingAddress.address,
      //   city: values.shippingAddress.city,
      //   postalCode: values.shippingAddress.postalCode,
      // },
      // base64Image: imageUrl,
    };

    try {
      const response = await ServerController.CoachAthlete.post(userRegistrationObject);
      if (!IsErrorResDto(response)) {
        getAndSetUser();
        navigate('/users');
        message.open(successMessage(t('userPage.athleteAdded')));
      } else {
        // handle error
      }
    } finally {
      setLoading(false);
    }
  };
  const [imageUrl, setImageUrl] = useState<string>();

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) {
      message.error('File not uploaded, please try again..');
      return;
    }
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
      return;
    }
    const isLessThen5M = file.size / 1024 / 1024 < 5;
    if (!isLessThen5M) {
      message.error('Image must smaller than 5MB!');
      return;
    }
    getBase64(file, (url) => {
      setLoading(false);
      setImageUrl(url);
    });
  };

  const getBase64 = (img: File, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  return (
    <div className="container">
      <Card title={t('userPage.addUser')} className="rounded shadow-sm">
        <Row className="mb-2">
          <Col md={2}>
            <label htmlFor="file" style={{ cursor: 'pointer' }}>
              {
                <input
                  type="file"
                  id="file"
                  name="file"
                  onChange={handleFileChange}
                  onClick={(e) => {
                    alert('This feature is not yet implemented.');
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  hidden
                />
              }
              {!imageUrl ? (
                <Avatar shape="square" size={64} icon={<UserAddOutlined />} />
              ) : (
                <Avatar shape="square" size={64} src={imageUrl}></Avatar>
              )}
            </label>
          </Col>
          <Col md={21}>
            {!imageUrl ? (
              <>
                <div>
                  <b>Add profile pic</b>
                </div>
                <div>Image size should be maximin 5 MB.</div>
                <span>File format: jpeg, png.</span>
              </>
            ) : (
              <>
                <label htmlFor="file">
                  <Button
                    type="link"
                    onClick={() => {
                      alert('This feature has not been enabled yet.');
                      return;
                    }}
                  >
                    <EditOutlined />
                    {t(`userPage.editImage`)}
                  </Button>
                </label>
                |
                <Button type="link" danger onClick={() => setImageUrl('')}>
                  <DeleteOutlined />
                  {t(`userPage.removeImage`)}
                </Button>
              </>
            )}
          </Col>
        </Row>

        <Form form={form} onFinish={handleRegister}>
          <FormItem
            name="email"
            rules={[
              {
                required: true,
                message: t(`${translationPrefix}.register.email.required`) as string,
                type: 'email',
              },
            ]}
          >
            <FloatInput type="email" label={t(`${translationPrefix}.register.email.label`)} required />
          </FormItem>
          <Form.Item
            name="password"
            rules={[
              { required: true, message: t(`${translationPrefix}.register.password.required`) as string },
              {
                min: 8,
                message: 'Password must be 8 or more characters',
              },
            ]}
          >
            <FloatInput label={t(`${translationPrefix}.register.password.label`)} required type="password" />
          </Form.Item>

          <FormItem
            name="repeatedPassword"
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: `${t(`${translationPrefix}.register.confirmPassword.required`)}`,
                type: 'string',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(`${t(`${translationPrefix}.register.confirmPassword.match`)}`));
                },
              }),
            ]}
          >
            <FloatInput type="password" required label={t(`${translationPrefix}.register.confirmPassword.label`)} />
          </FormItem>

          <Row className="row-form-item" gutter={12}>
            <Col xs={24} md={12}>
              <Form.Item
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: t(`${translationPrefix}.register.name.required`) as string,
                  },
                ]}
              >
                <FloatInput label={t(`${translationPrefix}.register.name.first`)} required />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                name="lastName"
                rules={[
                  { required: true, message: t(`${translationPrefix}.register.name.lastNameRequired`) as string },
                ]}
              >
                <FloatInput label={t(`${translationPrefix}.register.name.last`)} required />
              </Form.Item>
            </Col>
          </Row>
          {/* <FormItem
            name={['shippingAddress', 'country']}
            rules={[
              {
                required: true,
                message: t(`${translationPrefix}.register.shippingAddress.country.required`) as string,
              },
            ]}
          >
            <FloatSelect
              options={countriesMemo}
              showSearch
              label={t(`${translationPrefix}.register.shippingAddress.country.label`)}
              filterOption={(input, option) => {
                const regex = new RegExp(input, 'i');
                return regex.test(option!.label as string);
              }}
              required
            />
          </FormItem>
          <Row className="row-form-item" gutter={12}>
            <Col xs={24} md={12}>
              <Form.Item
                name={['shippingAddress', 'postalCode']}
                rules={[
                  {
                    required: true,
                    message: t(`${translationPrefix}.register.shippingAddress.postalCode.required`) as string,
                  },
                ]}
              >
                <FloatInput label={t(`${translationPrefix}.register.shippingAddress.postalCode.label`)} required />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <FormItem
                name={['shippingAddress', 'city']}
                rules={[
                  {
                    required: true,
                    message: t(`${translationPrefix}.register.shippingAddress.city.required`) as string,
                  },
                ]}
              >
                <FloatInput label={t(`${translationPrefix}.register.shippingAddress.city.label`)} required />
              </FormItem>
            </Col>
          </Row>
          <FormItem
            name={['shippingAddress', 'address']}
            rules={[
              {
                required: true,
                message: t(`${translationPrefix}.register.shippingAddress.address.required`) as string,
              },
            ]}
          >
            <FloatInput label={t(`${translationPrefix}.register.shippingAddress.address.label`)} required />
          </FormItem>

          <FormItem name={['shippingAddress', 'state']}>
            <FloatInput label={t(`${translationPrefix}.register.shippingAddress.state.label`)} />
          </FormItem>

          <FormItem
            name={['hasAcceptedTermsAndConditions']}
            label={
              <>
                {t(`${translationPrefix}.register.acceptTermsAndConditions.label`)}
                <span style={{ color: 'red', fontWeight: 'normal', fontSize: '14px' }}>*</span>
              </>
            }
            rules={[
              ({ getFieldValue }) => ({
                validator(_, __) {
                  if (hasAcceptedTermsAndConditions) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(t(`${translationPrefix}.register.acceptTermsAndConditions.required`) as string),
                  );
                },
              }),
            ]}
          >
            <Checkbox
              checked={hasAcceptedTermsAndConditions}
              onChange={(e: CheckboxChangeEvent) => {
                setHasAcceptedTermsAndConditions(e.target.checked);
              }}
            />
          </FormItem> */}

          <FormItem wrapperCol={sizing.xs === true ? undefined : { offset: 4, span: 16 }}>
            <Button type="primary" loading={loading} htmlType="submit" style={{ width: '100%' }}>
              {t(`save`)}
            </Button>
          </FormItem>
        </Form>
      </Card>
    </div>
  );
};
