import axios from 'axios';
import { API_URL } from '../../constants';
import { ErrorResDto, HandleAxiosErrorResDto } from '../error-res.dto';
import { PostPublicTokenLoginResDto } from './res/post-public-token-login-res.dto';

export class PublicTokenLoginServer {
  private readonly API_PATH: string = `${API_URL}/public/token/login`;

  async get(url: string): Promise<PostPublicTokenLoginResDto | ErrorResDto> {
    try {
      const response = await axios.get<PostPublicTokenLoginResDto>(`${this.API_PATH}${url}`);
      return response.data;
    } catch (err) {
      return HandleAxiosErrorResDto(err);
    }
  }
}
