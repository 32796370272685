import { AddUserPage } from './add-user.page';
import { AccountTypeEnum } from '../../types/account-type.enum';
import { UserEditPage } from './user-edit.page';
import { UserPage } from './user.page';
import { UserPageOutlet } from './user.page-outlet';

export const UserPageRoutes = {
  path: '/users',
  element: <UserPageOutlet />,
  requiredRoles: [AccountTypeEnum.Coach],
  subroutes: [
    {
      path: '',
      element: <UserPage />,
    },
    {
      path: '/users/:id',
      element: <UserEditPage />,
    },
    {
      path: '/users/add',
      element: <AddUserPage />,
    },
  ],
};
