import { useContext } from 'react';
import { AuthContext } from '../../../context/auth-context.provider';

import { UserOutlined } from '@ant-design/icons';

export default function UserTotalCountComponent() {
  const { user } = useContext(AuthContext);

  return (
    <>
      <UserOutlined style={{ fontSize: '25px', color: 'var(--ant-primary-color)' }} className="me-1" />
      <span className="me-2">
        {user?.countOfAddedUsers ?? 0}/ {user?.numberOfUsers ?? 0}
      </span>
    </>
  );
}
