import { message } from 'antd';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import { AppAuthGuard } from './pages/guards/app-auth.guard';

export const App = function App() {
  message.config({ maxCount: 1 });

  return (
    <Router>
      {/* <PaymentMethodGuard></PaymentMethodGuard> */}
      <AppAuthGuard></AppAuthGuard>
    </Router>
  );
};
