import { Col } from 'antd';
import { Row } from 'antd/es';
import { useCallback, useContext, useEffect, useState } from 'react';
import { PaymentMethodResDto } from '../../api/coach/res/coach-payment-method-res.dto';
import { SubscriptionResDto } from '../../api/coach/res/subcription-res.dto';
import { IsErrorResDto } from '../../api/error-res.dto';
import { ServerController } from '../../api/server.controller';
import { AuthContext } from '../../context/auth-context.provider';
import { AccountTypeEnum } from '../../types/account-type.enum';
import './account.css';
import { AvatarComponent } from './avatar';
import { UserInfoCard } from './user-info-card';

export const AccountPage: React.FC = function AccountPage() {
  const { user, isInRole, logout } = useContext(AuthContext);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodResDto | null>(null);
  const [subscription, setSubscription] = useState<SubscriptionResDto | null>(null);

  const loadPlan = useCallback(async () => {
    if (isInRole(AccountTypeEnum.Coach)) {
      const response = await ServerController.CoachPlan.get();
      if (!IsErrorResDto(response)) {
        setSubscription(response.data);
      } else {
        if(response.statusCode.toString().includes("403") ||response.statusCode.toString().includes("401") ){
          logout();
        }
      }
    }
  }, [isInRole]);

  const loadPaymentMethod = useCallback(async () => {
    if (isInRole(AccountTypeEnum.Coach)) {
      const response = await ServerController.CoachPayment.get();
      if (!IsErrorResDto(response)) {
        setPaymentMethod(response.data);
      } else {
        // handle error
      }
    }
  }, [isInRole]);
  useEffect(() => {
    loadPaymentMethod();
    loadPlan();
  }, [loadPlan, loadPaymentMethod]);

  return (
    <div className="container mt-3">
      <Row className=" d-flex align-items-center">
        <Col span="24" className="text-center">
          {user && <AvatarComponent user={user} size={{ xs: 24, sm: 32, md: 40, lg: 64, xl: 80, xxl: 100 }} showName />}
        </Col>
        <Col span="24">
          {user && <UserInfoCard user={user} subscription={subscription} paymentMethod={paymentMethod} />}
        </Col>
      </Row>
    </div>
  );
};
