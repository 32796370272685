import axios from 'axios';
import { API_URL } from '../../constants';
import { ErrorResDto, HandleAxiosErrorResDto } from '../error-res.dto';
import { PostCoachPlanReqDto } from './req/post-coach-plan-req.dto';
import type { GetCoachPaymentMethodResDto } from './res/get-coach-payment-method-res.dto';
import { GetCoachPlanResDto } from './res/get-coach-plan-res.dto';

export class CoachPlanServer {
  private readonly API_PATH: string = `${API_URL}/coach/plan`;

  async post(data: PostCoachPlanReqDto): Promise<GetCoachPaymentMethodResDto | ErrorResDto> {
    try {
      const response = await axios.post<GetCoachPaymentMethodResDto>(`${this.API_PATH}`, data);
      return response.data;
    } catch (err) {
      return HandleAxiosErrorResDto(err);
    }
  }
  async get(): Promise<GetCoachPlanResDto | ErrorResDto> {
    try {
      const response = await axios.get<GetCoachPlanResDto>(`${this.API_PATH}`);
      return response.data;
    } catch (error) {
      return HandleAxiosErrorResDto(error);
    }
  }
}
