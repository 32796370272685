import axios from 'axios';
import { API_URL } from '../../constants';
import { ErrorResDto, HandleAxiosErrorResDto } from '../error-res.dto';
import { PostCoachStripePaymentIntentReqDto } from './req/post-coach-stripe-payment-intent-req.dto';
import { GetCoachStripeSetupIntentResDto } from './res/get-coach-stripe-payment-intent-res.dto';

export class CoachStripePaymentIntentServer {
  private readonly API_PATH: string = `${API_URL}/coach/stripe/payment-intent`;

  async post(data: PostCoachStripePaymentIntentReqDto): Promise<GetCoachStripeSetupIntentResDto | ErrorResDto> {
    try {
      const response = await axios.post<GetCoachStripeSetupIntentResDto>(this.API_PATH, data);
      return response.data;
    } catch (err) {
      return HandleAxiosErrorResDto(err);
    }
  }
}
