import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeCardElementChangeEvent } from '@stripe/stripe-js';
import { Button } from 'antd';
import { Form, Input } from 'antd/es';
import { useForm } from 'antd/lib/form/Form';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentMethodResDto } from '../../../api/coach/res/coach-payment-method-res.dto';
import { IsErrorResDto } from '../../../api/error-res.dto';

import { ServerController } from '../../../api/server.controller';
import { CardSelector } from '../../../constants/card-svgs/credit-cards';
import { AuthContext } from '../../../context/auth-context.provider';

const fixAutocomplete = (): void => {
  document.querySelectorAll('.ant-select-selector input').forEach((e) => {
    e.setAttribute('autocomplete', 'stop-autocomplete');
  });
};

const fields = [{ name: 'sepa' }, { name: 'card' }];

interface PaymentFormProps {
  paymentMethod?: PaymentMethodResDto | null;
  onPaymentFinish: () => void;
  onPaymentError: (err: string) => void;
}

interface CardPaymentForm {
  card: string;
  billing_details: {
    name: string;
    email: string;
  };
}

const PaymentMethodForm = (props: PaymentFormProps): JSX.Element => {
  const { t } = useTranslation(['account']);
  const { user } = useContext(AuthContext);
  const { paymentMethod, onPaymentError, onPaymentFinish } = props;
  const stripe = useStripe();
  const elements = useElements();
  const [form] = useForm<CardPaymentForm>();
  const [cardError, setCardError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    fixAutocomplete();
  }, []);

  const handleCardElement = async (e: StripeCardElementChangeEvent): Promise<void> => {
    setCardError(e.error?.message ?? null);
    form.setFieldsValue({ card: e.empty || e.error?.message != null ? undefined : 'complete' });
    try {
      await form.validateFields(['card']);
    } catch {}
  };

  const handleFinish = async (values: any): Promise<void> => {
    setLoading(true);
    let paymentMethodId: string | null = null;
    try {
      if (user == null || stripe == null || elements == null || form == null) {
        throw new Error(t('paymentSetup.errors.stripe'));
      }
      const setupIntentRes = await ServerController.Stripe.fetchSetupIntent();
      let setupIntent;
      if (!IsErrorResDto(setupIntentRes)) {
        setupIntent = setupIntentRes.data;
      }
      if (setupIntent == null) {
        throw new Error(t('paymentSetup.errors.stripe'));
      }

      const cardElement = elements.getElement('card');
      if (cardElement == null) {
        throw new Error(t('paymentSetup.errors.stripe'));
      }

      const response = await stripe.confirmCardSetup(setupIntent.client_secret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            name: user.firstName + ' ' + user.lastName,
            email: user.email,
          },
        },
      });

      if (response.error != null) {
        throw new Error(response.error.message);
      }
      if (response.setupIntent == null) {
        throw new Error(t('paymentSetup.errors.stripe'));
      }

      paymentMethodId = response.setupIntent.payment_method as string;
      if (paymentMethodId) {
        onPaymentFinish();
      }
    } catch (err) {
      if (err instanceof Error) {
        onPaymentError(err.message);
      } else {
        onPaymentError(`${err}`);
      }
      return;
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form form={form} fields={fields} onFinish={handleFinish} labelCol={{ span: 6 }} wrapperCol={{ span: 19 }}>
      {paymentMethod && Object.keys(paymentMethod).length > 0 && (
        <Input
          readOnly
          addonBefore={CardSelector(paymentMethod.brand)}
          value={`**** **** **** ${paymentMethod.last4 ?? ''} ${paymentMethod.exp_month ?? ''}/${
            paymentMethod.exp_year ?? ''
          }`}
          style={{ marginBottom: 16 }}
        />
      )}
      <Form.Item
        name="card"
        rules={[
          {
            required: true,
            message: cardError ?? `You must fill all the details`,
          },
        ]}
        wrapperCol={{ span: 24 }}
      >
        <div className="ant-input" style={{ padding: '7px 11px' }}>
          <CardElement onChange={handleCardElement} />
        </div>
      </Form.Item>
      <Form.Item>
        <Button loading={loading} onClick={form.submit}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default PaymentMethodForm;
