import { Card } from 'antd';
import React, { MouseEventHandler } from 'react';

export interface PublicOneTimeProductResDto {
  id: string;

  normalizedName: string;

  displayName: string;

  currency: 'usd';

  amountInCents: number;

  metadata: string | null;

  base64Image: string;

  description: string | null;
}
interface Props {
  product: PublicOneTimeProductResDto;
  onClick?: MouseEventHandler;
}

export const ProductComponent: React.FC<Props> = ({ product, onClick }) => {
  return (
    <Card
      hoverable
      style={{ width: 240 }}
      cover={<img alt="product" src={`${product.base64Image}`} />}
      className="rounded shadow-sm mb-2"
      headStyle={{
        borderStartEndRadius: '1rem',
        borderStartStartRadius: '1rem',
      }}
      onClick={onClick?.bind(product)}
    >
      <Card.Meta title={product.displayName} description={product.description} />
      <div className="mt-2 text-end">
        <span className="fw-bold">
          {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(product.amountInCents / 100)}
        </span>
      </div>
    </Card>
  );
};
