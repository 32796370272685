import axios from 'axios';
import { API_URL } from '../../constants';
import { ErrorResDto, HandleAxiosErrorResDto } from '../error-res.dto';
import { PostPublicUpdatePersonalInfoReqDto } from './req/post-public-update-info-req.dto';
import { GetSharedUserResDto } from './res/get-shared-user-res.dto';

export class SharedUserServer {
  private readonly API_PATH: string = `${API_URL}/shared/user`;

  async get(): Promise<GetSharedUserResDto | ErrorResDto> {
    try {
      const response = await axios.get<GetSharedUserResDto>(`${this.API_PATH}`);
      return response.data;
    } catch (error) {
      return HandleAxiosErrorResDto(error);
    }
  }

  async patch(data: PostPublicUpdatePersonalInfoReqDto): Promise<GetSharedUserResDto | ErrorResDto> {
    try {
      const response = await axios.patch<GetSharedUserResDto>(`${this.API_PATH}`, data);
      return response.data;
    } catch (error) {
      return HandleAxiosErrorResDto(error);
    }
  }
}
