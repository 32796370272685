import { AccountTypeEnum } from '../../types/account-type.enum';
import { PlanPurchasePage } from './plan-purchase.page';
import { PlanPage } from './plan.page';
import { PlansOutlet } from './plans.outlet';

export const PlanPageRoutes = {
  path: '/plans',
  element: <PlansOutlet />,
  requiredRoles: [AccountTypeEnum.Coach, AccountTypeEnum.Athlete],
  subroutes: [
    {
      path: '',
      element: <PlanPage />,
    },
    {
      path: 'purchase/kat',
      element: <PlanPurchasePage />,
    },
  ],
};
