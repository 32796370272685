import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Alert, Modal, Spin } from 'antd/es';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { STRIPE_API_KEY } from '../../../constants/constants';

import { PaymentMethodResDto } from '../../../api/coach/res/coach-payment-method-res.dto';
import PaymentMethodForm from '../forms/payment-method-form';

const stripe = loadStripe(STRIPE_API_KEY);
interface PaymentProps {
  open: boolean;
  close?: () => void;
  paymentMethod?: PaymentMethodResDto | null;
}

const UpdatePayment = (props: PaymentProps): JSX.Element => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { open, paymentMethod, close } = props;
  const [paymentError, setPaymentError] = useState<string | null>(null);

  const onPaymentFinish = () => {
    setPaymentError(null);
    setLoading(false);
    close?.();
  };

  const onPaymentError = (err: string) => {
    setPaymentError(err);
  };

  return (
    <Modal
      title={t('accountPage.updatePayment')}
      footer={false}
      width={640}
      open={open}
      onCancel={close}
      destroyOnClose
      closable={Boolean(close)}
    >
      <Elements stripe={stripe}>
        <Spin spinning={loading}>
          {paymentError != null && <Alert type="error" message={paymentError} style={{ marginBottom: 24 }} />}
          <PaymentMethodForm
            paymentMethod={paymentMethod}
            onPaymentError={onPaymentError}
            onPaymentFinish={onPaymentFinish}
          />
        </Spin>
      </Elements>
    </Modal>
  );
};

export default UpdatePayment;
