import { message, Modal } from 'antd';
import { Form } from 'antd/es';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { IsErrorResDto } from '../../../api/error-res.dto';
import { ServerController } from '../../../api/server.controller';
import { PatchPasswordReqDto } from '../../../api/shared/req/patch-password-req.dto';
import { successMessage } from '../../../components/antd-notifications/antd-notifications';
import FloatInput from '../../../components/float-components/float-input';

interface Props {
  open: boolean;
  closeModal: () => void;
}

export interface ChangeCredentialsForm {
  oldValue: string;
  newValue: string;
  repeatedNewValue: string;
}

export function ChangePassword(props: Props) {
  const { t } = useTranslation();
  const { open, closeModal } = props;
  const [form] = useForm<PatchPasswordReqDto>();
  const translationPrefix = 'accountPage.components.changePassword';

  const onFinish = async (data: PatchPasswordReqDto) => {
    const response = await ServerController.SharedUpdatePassword.patch(data);
    if (!IsErrorResDto(response)) {
      message.open(successMessage(t('accountPage.updatePassword.success')));
      closeModal();
    } else {
      // handle error
      form.setFieldValue('serverError', response);
    }
  };

  return (
    <Modal
      open={open}
      destroyOnClose
      onCancel={() => {
        closeModal();
        form.resetFields();
      }}
      onOk={() => {
        form.resetFields(['serverError']);
        form.submit();
      }}
      title={t(`${translationPrefix}.title`)}
    >
      <Form<PatchPasswordReqDto> layout="vertical" form={form} onFinish={onFinish} labelCol={{ span: 8 }}>
        <Form.Item
          name={'oldPassword'}
          rules={[
            {
              required: true,
              message: `${t(`${translationPrefix}.form.password.required`)}`,
              type: 'string',
            },
          ]}
        >
          <FloatInput type="password" label={t(`${translationPrefix}.form.password.label`)} />
        </Form.Item>
        <Form.Item
          name={'newPassword'}
          rules={[
            {
              required: true,
              message: `${t(`${translationPrefix}.form.newPassword.required`)}`,
              type: 'string',
            },
          ]}
          hasFeedback
        >
          <FloatInput type="password" label={t(`${translationPrefix}.form.newPassword.label`)} />
        </Form.Item>

        <Form.Item
          name={'newRepeatedPassword'}
          dependencies={['newPassword']}
          hasFeedback
          rules={[
            {
              required: true,
              message: `${t(`${translationPrefix}.form.newRepeatedPassword.required`)}`,
              type: 'string',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(`${t(`${translationPrefix}.form.newRepeatedPassword.mustMatch`)}`));
              },
            }),
          ]}
        >
          <FloatInput type="password" label={t(`${translationPrefix}.form.newRepeatedPassword.label`)} />
        </Form.Item>
        {/* <Form.List
          name="serverError"
          rules={[
            {
              validator: async (_, serverErrors) => {
                if (serverErrors && serverErrors.message && serverErrors.message.length) {
                  return Promise.reject(new Error(serverErrors.message.join('\\n')));
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              <Form.ErrorList errors={errors} />
            </>
          )}
        </Form.List> */}
      </Form>
    </Modal>
  );
}
