import { AccountTypeEnum } from '../../types/account-type.enum';
import { AccountPage } from './account.page';
import { AccountPageOutlet } from './account.page-outlet';

export const AccountPageRoutes = {
  path: '/account',
  element: <AccountPageOutlet />,
  requiredRoles: [AccountTypeEnum.Coach, AccountTypeEnum.Athlete],
  subroutes: [
    {
      path: '',
      element: <AccountPage />,
    },
  ],
};
