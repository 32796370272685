export const AuthTokenSymbol = 'authToken';
export class AuthStorageUtility {
  static saveToken = (authToken: string): void => {
    window.localStorage.setItem(AuthTokenSymbol, authToken);
    this.tokenCache = null;
  };

  static deleteToken() {
    window.localStorage.removeItem(AuthTokenSymbol);
    this.tokenCache = null;
  }
  static tokenCache = null as any;
  static getToken() {
    if (this.tokenCache) {
      return this.tokenCache;
    }
    this.tokenCache = window.localStorage.getItem(AuthTokenSymbol);
    return this.tokenCache;
  }
}
