export const API_URL =
  process.env.REACT_APP_API_URL ||
  (() => {
    throw 'YOU MUST DEFINE .env variable named REACT_APP_API_URL';
  })();
export const LOGIN_CLIENT_URL =
  process.env.REACT_APP_LOGIN_CLIENT_URL ||
  (() => {
    throw 'YOU MUST DEFINE .env variable named REACT_APP_LOGIN_CLIENT_URL';
  })();
