import { AthleteServer } from './athlete/athlete.server';
import { CoachAthleteEmailRegistrationServer } from './coach/coach-athlete-email-registration.server';
import { CoachAthleteServer } from './coach/coach-athlete-server';
import { CoachPaymentMethodServer } from './coach/coach-payment-method.server';
import { CoachPlanServer } from './coach/coach-plan.server';
import { CoachStripePaymentIntentServer } from './coach/coach-stripe-payment-intent.server';
import { PublicOneTimeProductServer } from './public/public-one-time-product.server';
import { PublicPlanServer } from './public/public-plan.server';
import { PublicTokenLoginServer } from './public/public-token-login.server';
import { AuthServer } from './server-subclasses/auth';
import { Stripe } from './server-subclasses/stripe';
import { SharedUpdateEmailServer } from './shared/shared-update-email.server';
import { SharedUpdatePasswordServer } from './shared/shared-update-password.server';
import { SharedUserServer } from './shared/shared-user.server';

export class ServerController {
  static Auth: AuthServer = new AuthServer();
  static Stripe: Stripe = new Stripe();
  static PublicPlan: PublicPlanServer = new PublicPlanServer();
  static PublicTokenLogin = new PublicTokenLoginServer();
  static SharedUser = new SharedUserServer();
  static SharedUpdatePassword = new SharedUpdatePasswordServer();
  static CoachPayment = new CoachPaymentMethodServer();
  static SharedUpdateEmail = new SharedUpdateEmailServer();
  static CoachPlan = new CoachPlanServer();
  static CoachAthlete = new CoachAthleteServer();
  static PublicProduct = new PublicOneTimeProductServer();
  static CoachPaymentIntent = new CoachStripePaymentIntentServer();
  static CoachAthleteEmailRegistration = new CoachAthleteEmailRegistrationServer();
  static Athlete = new AthleteServer();
  static User = this.Athlete;
}
