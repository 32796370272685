import { Modal } from 'antd/es';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PersonalInfoForm } from '../forms/personal-info-form';

interface PersonalInfoProps {
  open: boolean;
  close: () => void;
}

export const UpdatePersonalInfo: React.FC<PersonalInfoProps> = function UpdatePersonalInfo(props: PersonalInfoProps) {
  const { t } = useTranslation();
  const { open, close } = props;
  const translationPrefix = 'accountPage';

  return (
    <Modal
      title={t(`${translationPrefix}.updatePersonalInfo`)}
      footer={false}
      width={640}
      open={open}
      onCancel={close}
      destroyOnClose
    >
      <PersonalInfoForm closeModal={close} />
    </Modal>
  );
};

export default UpdatePersonalInfo;
