import axios from 'axios';
import { API_URL } from '../../constants';
import { type ErrorResDto, HandleAxiosErrorResDto } from '../error-res.dto';
import type { PatchEmailReqDto } from './req/patch-email-req.dto';
import type { PatchSharedUpdateEmailResDto } from './res/patch-shared-update-email-res.dto';
import type { PatchSharedUpdatePasswordResDto } from './res/patch-shared-update-password-res.dto';

export class SharedUpdateEmailServer {
  private readonly API_PATH: string = `${API_URL}/shared/update/email`;

  async patch(data: PatchEmailReqDto): Promise<PatchSharedUpdatePasswordResDto | ErrorResDto> {
    try {
      const response = await axios.patch<PatchSharedUpdateEmailResDto>(`${this.API_PATH}`, data);
      return response.data;
    } catch (error) {
      return HandleAxiosErrorResDto(error);
    }
  }
}
