import { message, Modal } from 'antd';
import { Form } from 'antd/es';
import { useForm } from 'antd/lib/form/Form';
import { useTranslation } from 'react-i18next';
import { IsErrorResDto } from '../../../api/error-res.dto';
import { ServerController } from '../../../api/server.controller';
import { PatchEmailReqDto } from '../../../api/shared/req/patch-email-req.dto';
import { successMessage } from '../../../components/antd-notifications/antd-notifications';
import FloatInput from '../../../components/float-components/float-input';

interface Props {
  open: boolean;
  closeModal: () => void;
}

export interface ChangeCredentialsForm {
  oldValue: string;
  newValue: string;
  repeatedNewValue: string;
}

export function ChangeEmail(props: Props) {
  const { t } = useTranslation();
  const { open, closeModal } = props;
  const [form] = useForm<PatchEmailReqDto>();

  const translationPrefix = 'accountPage.components.changeEmail';

  const onFinish = async (data: PatchEmailReqDto) => {
    const response = await ServerController.SharedUpdateEmail.patch(data);
    if (!IsErrorResDto(response)) {
      message.open(successMessage(t('accountPage.updatePassword.success')));
    } else {
      // handle error
      form.setFieldValue('serverError', response);
    }
  };

  return (
    <Modal
      open={open}
      onCancel={() => {
        closeModal();
        form.resetFields();
      }}
      onOk={() => {
        form.submit();
      }}
      title={t(`${translationPrefix}.title`)}
    >
      <Form layout="vertical" form={form} onFinish={onFinish} labelCol={{ span: 8 }}>
        <Form.Item
          name={'oldEmail'}
          rules={[
            {
              required: true,
              message: `${t(`${translationPrefix}.form.email.required`)}`,
              type: 'email',
            },
          ]}
        >
          <FloatInput type="email" label={t(`${translationPrefix}.form.email.label`)} />
        </Form.Item>
        <Form.Item
          name={'newEmail'}
          rules={[
            {
              required: true,
              message: `${t(`${translationPrefix}.form.newEmail.required`)}`,
              type: 'email',
            },
          ]}
          hasFeedback
        >
          <FloatInput type="email" label={t(`${translationPrefix}.form.newEmail.label`)} />
        </Form.Item>

        <Form.Item
          name={'newRepetaedEmail'}
          dependencies={['newEmail']}
          hasFeedback
          rules={[
            {
              required: true,
              message: `${t(`${translationPrefix}.form.newRepeatedEmail.required`)}`,
              type: 'email',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(`${t(`${translationPrefix}.form.newRepeatedEmail.mustMatch`)}`));
              },
            }),
          ]}
        >
          <FloatInput type="email" label={t(`${translationPrefix}.form.newRepeatedEmail.label`)} />
        </Form.Item>
      </Form>
    </Modal>
  );
}
