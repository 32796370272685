import React from 'react';
import ReactDOM from 'react-dom/client';

import { ConfigProvider } from 'antd';
import { SetupAxiosAuthorizationInterceptor, SetupAxiosErrorInterceptor } from './api/axios-error.interceptor';
import { App } from './App';
import { AuthProvider } from './context/auth-context.provider';
import { GetAndInitI18N } from './i18n/i18n.config';
import './index.css';

SetupAxiosErrorInterceptor();
SetupAxiosAuthorizationInterceptor();
GetAndInitI18N();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ConfigProvider>
      <AuthProvider>
        <App />
      </AuthProvider>
    </ConfigProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
