import { AxiosError } from 'axios';

export interface ErrorResDto {
  message: string[];
  statusCode: number;
}

export const IsErrorResDto = function IsErrorResDto(unknownType: any): unknownType is ErrorResDto {
  return unknownType && 'message' in unknownType && Array.isArray(unknownType.message) && 'statusCode' in unknownType;
};

export const HandleAxiosErrorResDto = function HandleAxiosErrorResDto(err: unknown) {
  const error = err as AxiosError<ErrorResDto, any>;
  if (error.response) {
    return error.response.data;
  }
  return {
    statusCode: 500,
    message: ['Unknown server error has occurred..'],
  };
};
