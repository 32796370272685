import { Menu } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { useContext, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { AuthContext } from '../../context/auth-context.provider';
import { SideBarLinks } from './sidebar.links';

 function isValidHttpUrl(string: string) {
  let url;
  
  try {
    url = new URL(string);
  } catch (_) {
    return false;  
  }

  return url.protocol === "http:" || url.protocol === "https:";
}
export const SideBarComponent = () => {
  const navigate = useNavigate();

  const location = useLocation().pathname.split('/')[1];
  const { user } = useContext(AuthContext);
  const linksAvailableToUser = useMemo(() => {
    const links: ItemType[] = [];
    for (const link of SideBarLinks) {
      if (user?.roles.some((x) => link.requiredRoles.includes(x as any))) {
        links.push({
          icon: link.icon,
          key: link.key,
          label: link.label,          
        });
      }
    }
    return links;
  }, [user]);
  return (
    <Menu
      theme="dark"
      className="mt-3 py-3"
      mode="inline"
      selectedKeys={[location]}
      onClick={(item) => {
        if (isValidHttpUrl(item.key)) {
          window.open(item.key);
          return;
        }
        navigate(item.key);
      }}
      items={linksAvailableToUser}
    />
  );
};
