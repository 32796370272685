import { Spin } from 'antd';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { LOGIN_CLIENT_URL } from '../../constants';
import { AuthContext } from '../../context/auth-context.provider';
import { MainLayoutPage } from '../shared/layout/main-layout.page';

export function AppAuthGuard() {
  const { checkIfJwtIsValid, isAuthenticated, token, authenticateViaTokenUrl, getAndSetUser } = useContext(AuthContext);
  const [isLoading, setLoading] = useState(true);

  const location = useLocation();

  useEffect(() => {
    if (location.search?.includes('token')) {
      authenticateViaTokenUrl(location.search).finally(() => {
        getAndSetUser().finally(()=>{
          setLoading(false);
        })
      });
    } else if (token) {
      checkIfJwtIsValid().finally(() => {
          setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [checkIfJwtIsValid, token, location, authenticateViaTokenUrl, getAndSetUser]);

  const routerComponent = useCallback(() => {
    if (isLoading) {
      return <Spin />;
    }
    if (!isAuthenticated) {
      setTimeout(() => {
        window.location.href = LOGIN_CLIENT_URL;
      }, 1000);
      return <></>;
    }
    return <MainLayoutPage />;
  }, [isLoading, isAuthenticated]);

  return routerComponent();
}
