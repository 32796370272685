import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Avatar, Button, Card, Form, InputNumber, Spin } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { MouseEventHandler, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { InvoiceData } from '../../api/coach/res/coach-stripe-setup-intent-res.dto';
import { IsErrorResDto } from '../../api/error-res.dto';
import { ServerController } from '../../api/server.controller';
import { STRIPE_API_KEY } from '../../constants/constants';
import { CenteredSpinnerComponent } from './components/centered-spinner.component';
import { PurchaseDeviceComponent } from './components/purchase-device.component';

const stripePromise = loadStripe(STRIPE_API_KEY);

interface PageForm {
  amountOfKats: number;
}
const translationPrefix = 'planPurchasePage';

export const PlanPurchasePage = function PlanPurchasePage() {
  const { state } = useLocation();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isPaymentPending, setPaymentPending] = useState(false);
  const [clientSecret, setClientSecret] = useState('');
  const [invoiceData, setInvoiceData] = useState<null | InvoiceData>(null);

  const onClick: MouseEventHandler = async (x) => {
    setPaymentPending(true);

    x.preventDefault();
    x.stopPropagation();
    const response = await ServerController.CoachPaymentIntent.post({ katDevicesAmount: numberOfDevices });
    if (!IsErrorResDto(response)) {
      setPaymentPending(false);
      setClientSecret(response.data.client_secret);
      setInvoiceData(response.data);
    } else {
      setPaymentPending(false);
      setClientSecret('');
    }
  };

  const [numberOfDevices, setNumberOfDevices] = useState(1);
  const amount = useMemo(() => {
    return (state.amountInCents * numberOfDevices) / 100;
  }, [state, numberOfDevices]);
  const total = useMemo(() => {
    return (
      <span className="">
        {t(`${translationPrefix}.form.submitButton.label`)}{' '}
        {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(amount)} + VAT / Sales Tax
      </span>
    );
  }, [t, amount]);

  return (
    <div className="container">
      <Card className="rounded shadow-sm" title="Purchase more KATs to use with your License">
        <h5>
          Note: This purchase includes the hardware only, to be used with to be an existing license. It does not include
          additional licenses or user ID.s
        </h5>
        <Form>
          <FormItem name={'amountOfKats'} colon={false} label={<span className="mt-2">Number of devices</span>}>
            <div>
              <InputNumber
                title="Number of additional kat devices"
                min={1}
                max={5}
                defaultValue={1}
                bordered={false}
                className="me-2"
                onChange={(x) => {
                  setClientSecret('');
                  setNumberOfDevices(x ?? 0);
                }}
              />
              <Avatar src={state.base64Image} size={64} />
              <span className="fw-bold ms-2">
                {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                  state.amountInCents / 100,
                )}{' '}
                + VAT / Sales Tax
              </span>
            </div>
          </FormItem>
        </Form>

        <Button type="primary" onClick={onClick} disabled={isPaymentPending} className="mb-2">
          {isLoading ? <Spin spinning={isLoading} /> : total}
        </Button>

        {isPaymentPending ? (
          <CenteredSpinnerComponent spinning={isPaymentPending} />
        ) : (
          clientSecret && (
            <Elements stripe={stripePromise} options={{ clientSecret }}>
              <PurchaseDeviceComponent />
            </Elements>
          )
        )}
      </Card>
    </div>
  );
};
